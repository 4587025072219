<template>
  <div class="register-box">
    <el-card shadow="never" class="card">
      <p class="header">注册账号</p>
      <div class="formBox">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="phone">
            <el-input
              placeholder="手机号"
              v-model.trim="form.phone"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input
              placeholder="登陆密码"
              v-model.trim="form.passWord"
              type="password"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item prop="passWord2">
            <el-input
              placeholder="确认密码"
              v-model.trim="form.passWord2"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input placeholder="验证码" v-model.trim="form.code">
              <span slot="suffix" class="getCode" @click="_getCode">
                {{ show ? '获取验证码' : `${count}s后重新获取` }}
              </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="recommendCode">
            <el-input
              placeholder="推荐人编码（如有）"
              v-model.trim="form.recommendCode"
              onkeyup="value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            ></el-input>
          </el-form-item>
        </el-form>
        <p @click="_confirmRegister">确认注册</p>
        <p>
          <span>已经拥有账号？</span>
          <span @click="() => this.$router.push('/login')">登陆</span>
        </p>
      </div>
    </el-card>
    <!--协议弹窗-->
    <el-dialog
      :visible.sync="dialogVisible"
      fullscreen
      :before-close="beforeClose"
    >
      <p class="dia-header">注册协议</p>
      <p class="txt">
        【审慎阅读】 您在申请注册流程中点击同意前，应当认真阅读以下协议。
        <br />
        请您务必审慎阅读、充分理解协议中相关条款内容，其中包括但不限于：
        <br />
        1、与您约定免除或限制责任的条款；
        <br />
        2、与您约定的个人信息收集和使用的条款；
        <br />
        3、与您约定的数字证书的申请和使用的条款；
        <br />
        4、其他以粗体标识的重要条款内容。
        <br />
        如您对协议有任何疑问，可向平台客服咨询。
        <br />
        【特别提示】当您按照注册页面提示填写信息、阅读并同意协议且完成全部注册程序后，即表示您已充分阅读、理解并接受协议的全部内容。
        <br />
        阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。
        <br />
      </p>
      <p class="protocol">
        <span @click="handleHrefProtocol('/service-agreement')">
          《乐票闪贴服务协议》
        </span>
        <span @click="handleHrefProtocol('/privacy-policy')">《隐私政策》</span>
        <el-checkbox v-model="checked" style="line-height: 42px">
          我已阅读并同意注册协议
        </el-checkbox>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" :disabled="!checked" @click="handleConfirm">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRegister, getPhoneCode } from './api'
import config from '@/config'

const TIME_COUNT = 60
export default {
  data() {
    // 验证二次输入密码
    const validatePwd2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.passWord) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    // 验证是否是正确格式的手机号
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确格式的手机号'))
        }
      }
    }
    // 验证正确的密码格式: 6-16位，有数字和字母
    const passwordTest = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入6-18位并且必须包含数字和字母的密码'))
        }
      }
    }
    const rules = {
      phone: [{ validator: validatePhone, trigger: 'blur' }],
      passWord: [{ validator: passwordTest, trigger: 'blur' }],
      passWord2: [
        {
          required: true,
          validator: validatePwd2,
          trigger: 'blur',
        },
      ],
      code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
    }
    return {
      show: true, // 开始的时候启用(可以发验证码)
      count: '',
      rules,
      timer: null,
      dialogVisible: true,
      checked: false,
      form: {
        phone: '',
        passWord: '',
        passWord2: '',
        code: '',
        recommendCode: '',
      },
    }
  },
  mounted() {
    if (this.$route.query.isChecked === '1') {
      this.dialogVisible = false
    }
  },
  methods: {
    handleHrefProtocol(url) {
      const res = this.$router.resolve(url)
      window.open(res.href, '_blank')
    },
    async _getCode() {
      if (!this.show || !this.form.phone) {
        return
      }
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        try {
          const params = {
            phoneNumber: this.form.phone,
            templateId: 2,
          }
          await getPhoneCode(params)
          this.$message.success('发送成功，请注意查收')
        } catch (err) {
          console.error(err)
        }
      }
    },
    _confirmRegister() {
      const params = { ...this.form }
      delete params.passWord2
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const result = await getRegister(params)
            if (process.env.VUE_APP_NODE_ENV === 'prod') {
              window.location.href = `http://10.122.83.51:6060/receipt-api/register?userId=${result.userId}`
            } else if (process.env.VUE_APP_NODE_ENV === 'dev') {
              window.location.href = `http://10.103.250.81:6060/receipt-api/register?userId=${result.userId}`
            } else {
              window.location.href = `${config.baseUrl}/receipt-api/register?userId=${result.userId}`
            }
          } catch (err) {
            console.error(err)
          }
        }
      })
    },
    beforeClose(done) {
      this.$confirm('确认放弃注册返回登陆页面？')
        .then(() => {
          done()
          this.$router.push('/login')
        })
        .catch(() => {})
    },
    handleCancel() {
      this.$confirm('确认放弃注册返回登陆页面？')
        .then(() => {
          this.$router.push('/login')
        })
        .catch(() => {})
    },
    handleConfirm() {
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped lang="scss">
.register-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 72px 16px 72px;
  .card {
    width: 100%;
    height: 100%;
    .header {
      position: relative;
      height: 60px;
      line-height: 60px;
      width: 100%;
      font-size: 24px;
      color: #333;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 32px;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 32px;
        background: #2c68ff;
        top: 14px;
        left: -20px;
      }
    }
    .formBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      form {
        width: 344px;
        .getCode {
          color: #2c68ff;
          padding-right: 6px;
          position: relative;
          top: 8px;
          cursor: pointer;
          font-size: 14px;
        }
      }
      & > p:nth-child(2) {
        width: 344px;
        height: 56px;
        background: #2c68ff;
        border-radius: 4px;
        text-align: center;
        line-height: 56px;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
        margin-bottom: 32px;
      }
      & > p:nth-child(3) {
        font-size: 16px;
        & > span:first-child {
          color: #ccc;
        }
        & > span:nth-child(2) {
          color: #2c68ff;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep.el-dialog__footer {
    text-align: center;
  }
  .dia-header {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    color: #2c68ff;
    border-left: 8px solid #2c68ff;
    padding-left: 16px;
  }
  .txt {
    line-height: 36px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .protocol {
    display: flex;
    flex-direction: column;
    span {
      cursor: pointer;
      line-height: 42px;
      color: #2c68ff;
      font-size: 14px;
    }
  }
}

::v-deep.el-input__inner {
  height: 56px;
}
::v-deep.el-dialog {
  box-sizing: border-box;
  padding: 0 48px 48px 48px;
}
</style>
